<template>
  <div>
    <login></login>
    <div class="login">
      <div>
        <p class="title">登录</p>
        <p class="ms">
          还没有账号？ <router-link to="/reg">免费注册</router-link>
        </p>
      </div>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="密码登录">
          <a-form-model ref="ruleForm" :model="form" :rules="rules">
            <a-form-model-item
              ref="name"
              label="用户名"
              prop="name"
              :labelCol="{ span: 4, offset: 0 }"
              labelAlign="left"
            >
              <a-input placeholder="请输入您的账号" v-model="form.name">
                <a-icon slot="prefix" type="user"
              /></a-input>
            </a-form-model-item>
            <!-- <a-form-model-item label="用户名">
             
            <a-input v-model="form.name" placeholder="请输入您的账号">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item> -->

            <a-form-model-item
              label="密码"
              prop="pass"
              ref="pass"
              :labelCol="{ span: 4, offset: 0 }"
              labelAlign="left"
            >
              <a-input-password
                placeholder="请输入您的密码"
                v-model="form.pass"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input-password>
            </a-form-model-item>
            <p style="overflow: hidden">
              <router-link to="/pass" class="for" style="float: right"
                >忘记密码？</router-link
              >
            </p>

            <a-form-model-item>
              <a-button type="primary" @click="onSubmit" style="width: 100%">
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="2" tab="验证码登录" force-render>
          <a-form-model ref="ruleFormReg" :model="form" :rules="rules">
            <a-form-model-item
              ref="phone"
              label="手机号（国际/港澳台号码格式为：国家区号+手机号码）"
              prop="phone"
              :labelCol="{ span: 24, offset: 0 }"
              labelAlign="left"
            >
              <a-input placeholder="请输入您手机号" v-model="form.phone">
                <a-icon slot="prefix" type="tablet"
              /></a-input>
            </a-form-model-item>
            <!-- <a-form-model-item label="用户名">
             
            <a-input v-model="form.name" placeholder="请输入您的账号">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item> -->

            <a-form-model-item
              ref="code"
              prop="code"
              label="验证码"
              :labelCol="{ span: 24, offset: 0 }"
              labelAlign="left"
              style="width: 77%; display: table-cell"
            >
              <a-input placeholder="请输入您的验证码" v-model="form.code">
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              style="width: 25%; display: table-cell; padding-top: 27px"
            >
              <a-button
                type="primary"
                :disabled="codeBtn"
                @click="codeFun"
                style="width: 96px; padding: 0; margin-left: 5px"
              >
                {{ codeText }}
              </a-button>

              <a-drawer
                title="温馨提示"
                placement="top"
                :closable="false"
                :visible="visible"
                :after-visible-change="afterVisibleChange"
                @close="onClose"
                width="400"
              >
                <p>1.请确认输入的手机号是否为您注册的手机号；</p>
                <p>2.请检查短信是否被手机安全软件拦截；</p>
                <p>3.由于网络原因，短信可能会延迟；</p>
                <p>4.若多次未收到短信，请咨询客服。</p>
              </a-drawer>
            </a-form-model-item>
            <p style="overflow: hidden">
              <span class="codeQ" @click="showDrawer">没收到验证码？</span>
            </p>
            <a-form-model-item>
              <a-button type="primary" @click="onSubmitReg" style="width: 100%">
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import login from "../components/login.vue";
export default {
  components: {
    login,
  },
  data() {
    return {
      visible: false,
      btnSize: { span: 23, offset: 2 },
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      other: "",
      codeBtn: true,
      phoneCode: true,
      codeText: "发送验证码",
      countdown: 5,
      form: {
        name: "",
        pass: "",
        code: "",
        phone: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入您的账号",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入您的验证码",
            trigger: "blur",
          },
          {
            min: 1,
            max: 6,
            message: "请输入1-6个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入您的手机号",
            trigger: "blur",
          },
          {
            validator: this.checkGrade,
            trigger: "blur",
          },
        ],
        // region: [
        //   {
        //     required: true,
        //     message: "Please select Activity zone",
        //     trigger: "change",
        //   },
        // ],
        // date1: [
        //   { required: true, message: "Please pick a date", trigger: "change" },
        // ],
        // type: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "Please select at least one activity type",
        //     trigger: "change",
        //   },
        // ],
        // resource: [
        //   {
        //     required: true,
        //     message: "Please select activity resource",
        //     trigger: "change",
        //   },
        // ],
        // desc: [
        //   {
        //     required: true,
        //     message: "Please input activity form",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    codeFun() {
      this.settime();
    },
    settime() {
      //发送验证码倒计时
      if (this.countdown == 0) {
        this.countdown = 5;
        this.codeText = "发送验证码";

        this.codeBtn = this.phoneCode;
        return;
      } else {
        this.codeText = this.countdown;
        this.codeBtn = true;

        this.countdown--;
      }
      setTimeout(() => {
        this.settime();
      }, 1000);
    },
    checkGrade(rule, value, callback) {
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的手机号"));
        this.codeBtn = true;
        this.phoneCode = true;
      } else {
        callback();
        this.codeBtn = false;
        this.phoneCode = false;
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmitReg() {
      this.$refs.ruleFormReg.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // resetForm() {
    //   this.$refs.ruleForm.resetFields();
    // },
    onFieldBlur() {
      console.log("ZHELI ");
    },
    callback() {},
  },
};
</script>
<style  lang="scss" scoped>
.login {
  max-width: 450px;
  padding: 10px;
  margin: 0 auto;
  margin-top: 100px;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .codeQ {
    color: #69a4fa;
    cursor: pointer;
    float: right;
  }
  .ms {
    font-size: 14px;
  }
  .for {
    // float: right;
    // margin: -15px 15px 15px;
    // display: block;
    // text-align: right;
  }
}
.ant-form-item-label {
  display: block !important;
  text-align: left;
}
// .ant-form-item-label{
//   display: block;
//   text-align: left;
// }
</style>
