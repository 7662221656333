<template>

    <div class="banner">
      <div class="wid">
        <p>希冀-K12</p>
        <p>
          使用一种计算机语言编写一串指令的工作，就叫作“编程”。计算机自己不会思考，它只会按照指令行动。想让它完成复杂的任务，就必须把这个任务分解成一个个具体的指令：每一步具体做什么？按照什么顺序做？计算机程序本质上就是一系列指令的集合。
        <!-- <el-button round style='display:block;margin-top:20px;'>XXX</el-button> -->
        </p>
        
      </div>
    </div>
   

</template>
<script>
export default {
  name: "banner",
  components: {},
  data() {
    return {};
  },
  created: function () {},
  methods: {
   
  },
};
</script>
<style  lang="scss" scoped>
ul,
li,
div,
p,
span {
  list-style: none;
  padding: 0;
  margin: 0;
}

  .banner {
    width: 100%;
    background: url(../assets/1661908926499.jpg)
      no-repeat center;
    background-size: cover;
    height: 300px;
    .wid {
      width: 1180px;
      margin: 0 auto;
 
      padding:0 20px;
      p{
        text-align: left;
        color:#fff;
        width:500px;
        
        &:first-child{
            font-size:28px;
            font-weight: bold;
            padding-top:30px;
                 word-break: break-all;
        }
    
      }
      .btn{
        // float:left
      }
    }
  }
  @media (max-width:800px) {
     .banner .wid{
      width:90%;
     }
     .banner .wid p{
      width:100%;
     }
      
    
  }

  
  
</style>
