<template>
  <div id="box" class="box">
    <div class="box box-bg"></div>

    <router-link to="/index" style="position: relative">
      <p class='title'> <img src="https://www.xuezhiqiao.com/images/cgicon.png" width="50px" /><span style="margin-left:10px;">希冀</span></p>
      <p class='detail'>学习编程，提升逻辑思维能力、程序设计能力、问题分析与解决能力以及创新创造能力</p>

      <a-button
        type="dashed"
        ghost
        @click="toIndex"
        style=" width: 150px; height: 40px; font-size: 16px"
        >开始学习</a-button
      >
    </router-link>
    <!-- <scroll></scroll> -->
  </div>
</template>

<script>
import headTop from "../components/header.vue";
// import scroll from '../components/scroll.vue'
export default {
  name: "box",
  components: {
    headTop,
    //  scroll
  },
  data() {
    return {};
  },
  created: function () {
   
  },
  methods: {
    toIndex() {
      this.$router.push("/index");
      // window.localStorage.setItem('anjing-to','index')
    },
  },
};
</script>

<style  scoped>


.start {
  /* margin-top: 100px; */
}
.box {
  height: 100vh;
  background: url(../assets/home.gif) no-repeat;
  background-size: cover;
  /* height:300px;
    background:linear-gradient(red,green,black); */
  /* border-radius: 0 0 1000px 600px; */
}
.box-bg {
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
}
.title{
  padding-top: 15%;
  font-size:24px;
  color:#fff
}
.detail{
  width:350px;
  margin:10px auto 20px;
  font-size:14px;
  color:rgb(182, 182, 182)
}
</style>
