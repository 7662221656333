<template>
  <div>
    <headTop :currentrStr="currentrStr"></headTop>

    <div id="box" class="box">
      <!-- <span
      style="
        height: 50px;
        width: 50px;
        right: 8%;
        top: 40%;
        background: hsla(0, 0%, 100%, 0.1);
      "
    ></span>
    <span
      style="
        right: 4%;
        top: 10%;
        background: hsla(0, 0%, 100%, 0.1);
        height: 70px;
        width: 70px;
      "
    ></span>
       <span
      style="
        left: 4%;
        top: 10%;
        background: hsla(0, 0%, 100%, 0.1);
        height: 100px;
        width: 100px;
      "
    ></span>

    <span
      style="
        bottom: 100px;
        left: 8%;
        background: rgba(255, 255, 255, 0.1);
        width: 60px;
        height: 60px;
      "
    ></span>
    <span
      style="
        bottom: 100px;
        right: 15%;
        background: rgba(255, 255, 255, 0.1);
        width:40px;
        height: 40px;
      "
    ></span>
      <span
      style="
        bottom: 400px;
        top: 30%;
        background: rgba(255, 255, 255, 0.1);
        width:100px;
        height: 100px;
      "
    ></span> -->
      <!-- <div class='index-all'>
      还没有账号？ <router-link to="/reg"> 去注册</router-link>
    </div>
     <div style="color: #fff; margin-top: 20px" v-if='widthL'>
      已有账号? <router-link to="/login"> 登录到您的账户</router-link>
    </div> -->
      <div
        style="
          padding-top: 10%;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        "
      >
        请选择身份，开始你的编程之旅吧!
      </div>
      <ul>
        <li @click="to('/login-reg-pass', 'student')">
          <div>学生</div>
          <p>和你的同学一起来进行有趣的编程探险吧</p>
          <img
            src="https://bth.educg.net/images/addImages/scratch-js.png"
            width="30"
            class="image"
          />
        </li>
        <li @click="to('/login-reg-pass', 'teacher')">
          <div>老师</div>
          <p>教室管理、学习跟踪和课程计划。免费注册！</p>
          <img
            src="	https://bth.educg.net/images/addImages/a1.png"
            width="30"
            class="image"
          />
        </li>
        <li @click="to('login-reg-pass', 'parents')">
          <div>家长</div>
          <p>让您的孩子开始接触计算机科学，您也可以实时跟进他们的学习进展</p>
          <img
            src="https://bth.educg.net/images/addImages/a2.png"
            width="30"
            class="image"
          />
        </li>
      </ul>

      <!-- <div style="color: #fff; margin-top: 20px" v-if='!widthL'>
      已有账号? <router-link to="/login"> 登录到您的账户</router-link>
    </div> -->
    </div>
  </div>
</template>

<script>
 //EduCGRunning  //服务运行状态检测点；
import headTop from "../components/header.vue";
export default {
  name: "box",
  components: { headTop },
  data() {
    return {
      widthL: false,
      currentrStr:[]
    };
  },
  mounted: function () {
    if (document.body.clientWidth <= 800) {
      this.widthL = true;
    }

    window.onresize = () => {
      if (document.body.clientWidth <= 800) {
        this.widthL = true;
      }
    };
  },
  methods: {
    to(tar, group) {
      window.localStorage.setItem("k12-group", group);
      if (group == "teacher") {
        window.localStorage.setItem("k12-type", "ter");
      } else if (group == "student") {
        window.localStorage.setItem("k12-type", "list");
      } else {
        window.localStorage.setItem("k12-type", "pre");
      }
      if (
        window.localStorage.getItem("anjing-token-group") &&
        window.localStorage.getItem("anjing-token-group") != ""
      ) {
        // 说明已经登陆了
        if (
          group == "teacher" &&
          window.localStorage.getItem("anjing-token-group") == "teacher"
        ) {
          this.$router.push({ path: "./ter" });
        } else if (
          group == "student" &&
          window.localStorage.getItem("anjing-token-group") == "student"
        ) {
          this.$router.push({ path: "./list" });
        } else if( group == "parents" &&
          window.localStorage.getItem("anjing-token-group") == "parents"){
          this.$router.push({ path: "./pre" });
        }
        else {
          this.$router.push({ path: tar });
        }
      } else {
        this.$router.push({ path: tar });
      }
      
    },
  },
};
</script>

<style  lang="scss" scoped>
ul,
li,
div,
p,
span {
  list-style: none;
  padding: 0;
  margin: 0;
}
.box {
  min-height: calc(100vh - 48px);
  // background: linear-gradient(45deg, #ffffff,#e3e3e3,#d2d2d2, transparent);
  background: url(../assets/bg00.png) no-repeat;
  background-size: cover;
  position: relative;
  span {
    display: inline-block;
    border-radius: 100px;
    position: absolute;
  }
}

ul {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  flex-wrap: wrap;
}
ul li {
  cursor: pointer;
  float: left;
  /* width:30%; */
  margin-right: 3.3333333%;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  // box-shadow:1px -2px 14px 4px #dbdbdb;
  margin-bottom: 10px;
  &:hover {
    box-shadow: 1px 1px 1px #ccc;
  }
  .image {
    width: 300px;
  }
  div {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    text-align: left;
    width: 300px;
    margin-bottom: 10px;
    height: 50px;
  }
}
ul li:last-child {
  margin-right: 0%;
}
.index-all {
  color: #fff;
  padding-top: 200px;
}
@media (max-width: 800px) {
  .index-all {
    padding-top: 50px;
  }

  .box {
    height: auto;
  }
}
@media (max-width: 640px) {
  ul li {
    margin-right: 0;
  }
}
</style>
