<template>
  <div>
    <headTop :currentrStr="currentrStr" sfType="家长"></headTop>
 
  <div class="about">
    <banner></banner>
    <div class="about-all">
      <div class="title">免费课程<a style="font-size:14px;margin-left:20px;">更多课程</a></div>
      <!-- <ul>
          <li @click="to(item.type,item.number)" v-for="item in arryData" :key="item">
                
                <img
                  :src="item.img"
                  class="image"
                  width="100%"
                />
              
                <p>{{item.title}}</p>
                <span class="go-learn">去学习</span>
              </li>
        </ul> -->
      <el-carousel
        :interval="4000"
        type="card"
        :height="heightChange"
        class="addL"
      >
      <el-carousel-item v-for="item in arryData" :key="item">
            <ul>
              <li @click="to(item.url)">
                <div>{{item.title}}</div>
                <p>{{item.detail}}</p>
                <img
                  :src="item.img"
                  class="image"
                  width="100%"
                />
              </li>
            </ul>
          </el-carousel-item>
      </el-carousel>
     

      <div class="title">
        关联账号的任务
        <a-button type="primary" style="float: right" @click="showModal"
          >添加任务</a-button
        >
      </div>
      <div>
        <a-table
          bordered
          :columns="columnsR"
          :data-source="dataR"
          :scroll="{ x: 1000, y: 300 }"
        >
          <span slot="action" slot-scope="text">已完成</span>
        </a-table>
      </div>
    </div>
    <!-- <a-dialog title="添加任务" :visible.sync="dialogFormVisible">
      <a-form :model="form">
        <a-form-item label="任务名称" :label-width="formLabelWidth">
          <a-input v-model="form.name" autocomplete="off"></a-input>
        </a-form-item>
        <a-form-item label="任务ID" :label-width="formLabelWidth">
          <a-input v-model="form.name" autocomplete="off"></a-input>
        </a-form-item>
        <a-form-item label="指定班级" :label-width="formLabelWidth">
          <a-input v-model="form.name" autocomplete="off"></a-input>
        </a-form-item>
        
      </a-form>
      <div slot="footer" class="dialog-footer">
        <a-button @click="dialogFormVisible = false">取 消</a-button>
        <a-button type="primary" @click="dialogFormVisible = false"
          >确 定</a-button
        >
      </div>
    </a-dialog> -->
    <a-modal
      title="添加任务"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      okText="确认"
      cancelText="取消"
      @cancel="handleCancel"
    >
      <p>
        <a-input placeholder="任务名称" />
        <br />
        <br />
        <a-input placeholder="任务名称" />
        <br />
        <br />
        <a-input placeholder="任务名称" />
      </p>
    </a-modal>
  </div>
  </div>
</template>
<script>
import banner from "../components/banner.vue";
import headTop from '../components/header.vue'
const columnsR = [
  { title: "日期", dataIndex: "name", key: "name" },
  { title: "任务名称", dataIndex: "age", key: "age" },
  { title: "任务ID", dataIndex: "address", key: "1" },
  { title: "指定班级", dataIndex: "address", key: "2" },
  {
    title: "状态",
    key: "operation",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
    fixed: "right",
  },
];

const dataR = [];
for (let i = 0; i < 3; i++) {
  dataR.push({
    key: i,
    name: `2022-09-09`,
    age: "雨中漫步",
    address: `12121221${i}`,
  });
}
export default {
  name: "list",
  components: { banner ,headTop},
  data() {
    return {
      currentrStr:['index'],
      heightChange: "300px",
      columnsR,
      dataR,
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      arryData:[
        {
          title:'Scratch编程',
     
          img:'https://bth.educg.net/images/addImages/scratch-rumen.png',
          url:true
      
         
        },
        {
          title:'pYthon编程',
          detail:'',
          img:'https://bth.educg.net/images/WechatIMG111.png',
          url:false
         
        },
        {
          title:'C++编程',
          detail:'',
          img:'https://bth.educg.net/images/WechatIMG1091.png',
          url:false
       
        }
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "编程2班",
          zuoye: "小猫回家",
          type: "已完成",
        },
        {
          date: "2016-05-04",
          name: "家长",
          zuoye: "雨中漫步",
          type: "已完成",
        },
        {
          date: "2016-05-01",
          name: "编程2班",
          zuoye: "丢垃圾",
          type: "未完成",
        },
        {
          date: "2016-05-03",
          name: "编程2班",
          zuoye: "雨中漫步",
          type: "已完成",
        },
      ],
    };
  },
  mounted: function () {
    if( window.localStorage.getItem('anjing-token-group') !='parents'){
      this.$router.push({
        path: "./index",
      });
    }
    if (document.body.clientWidth <= 800) {
      this.heightChange = "180px";
    }
    if (document.body.clientWidth > 800 && document.body.clientWidth < 1000) {
      this.heightChange = "300px";
    }
    window.onresize = () => {
      if (document.body.clientWidth <= 800) {
        this.heightChange = "180px";
      }
      if (document.body.clientWidth > 800 && document.body.clientWidth < 1000) {
        this.heightChange = "300px";
      }
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    to(url) {
      // onclick="targetUrl('/userInfo/scratch.html?type=Scratch编程入门&pathNum=0')"
      if(url){
        this.$router.push({ path: 'class',query:{type:'scratch'} });
      }
    
    },
    // handleClick(row) {
    //   this.$router.push({
    //     path: "/detailVideo?title=课前准备&qid=1649985978179&userid=60191dab0d0f5248cc7c26d3&jobid=&id=62305e6ae9e1627608653807",
    //   });
    // },
  },
};
</script>
<style  lang="scss" scoped>
ul,
li,
div,
p,
span {
  list-style: none;
  //   padding: 0;
  //   margin: 0;
}
.about-all {
  padding: 0 10px;
  width: 1180px;
  margin: 0 auto;
}
.about {
  .title {
    padding: 20px 10px 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  .zuoye {
    display: flex;
    justify-content: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    // padding:5px 10px;
    justify-content: space-around;
  }
  ul li {
    margin-right: 3%;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    &:hover {
      box-shadow: 1px 1px 1px #ccc;
    }
    div {
      font-size: 24px;
      font-weight: bold;
    }
    p {
      text-align: left;
      // width: 235px;
      margin-bottom: 10px;
      text-align: center;
    }
  }
  ul li:last-child {
    margin-right: 0%;
  }
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 31.3%;
  //   display: inline-block;
  float: left;
  margin-right: 3.3333333%;
}
.a-card__header {
  background: #ececec;
}
.addL {
  width: 78%;
  margin: 0 auto;
}
.go-learn{
  // margin-top:10px;
  display: block;
  width:100%;
  height:30px;
  line-height: 30px;
  text-align: center;
  color:#fff;
  background: #1890ff;
}
@media (max-width: 1000px) {
  .about-all {
    width: 100%;
  }
  .about ul {
    width: 304px;
  }
}
@media (max-width: 800px) {
  .about-all {
    width: 100%;
  }
  .about ul li div {
    font-size: 16px;
  }
  .about ul li p {
    font-size: 12px;
  }
  .addL {
    width: 93%;
  }
  .about ul {
    width: 169px;
  }
}
</style>
