var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('a-container',{staticStyle:{"border":"1px solid #eee"}},[_c('a-aside',{staticStyle:{"background-color":"rgb(238, 241, 246)"},attrs:{"width":"200px"}},[_c('div',{staticStyle:{"height":"60px","line-height":"60px"}},[_vm._v("学生")]),_c('a-menu',{attrs:{"default-openeds":['1', '3']}},[_c('a-submenu',{attrs:{"index":"1"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"a-icon-orange"}),_vm._v("4~8岁")]),_c('a-menu-item',{attrs:{"index":"1-1"}},[_vm._v("第一章")]),_c('a-menu-item',{attrs:{"index":"1-2"}},[_vm._v("第二章")])],2)],1)],1),_c('a-container',[_c('a-header',{staticStyle:{"text-align":"left","font-size":"16px","color":"#fff"}},[_c('div',[_vm._v("当前视频:")])]),_c('a-main',{staticClass:"right"},[_c('div',[_c('iframe',{staticStyle:{"margin-top":"-20px"},attrs:{"width":"100%","height":"605","src":"https://www.ixigua.com/iframe/7011347773907796511?autoplay=0","scrolling":"no","border":"0","frameborder":"no","framespacing":"0","allowfullscreen":"true"}})]),_c('a-button',{staticStyle:{"flex":"1","height":"40px","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.doLx(
              '1649985978179',
              '课前准备',
              '60191dab0d0f5248cc7c26d3',
              '',
              '62305e6ae9e1627608653807'
            )}}},[_vm._v(" 开始练习 "),_c('i',{staticClass:"a-icon-edit"})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }