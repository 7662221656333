<template>
  <div>
    <headTop :currentrStr="currentrStr" sfType="学生"></headTop>

    <div class="about">
      <banner></banner>

      <div class="about-all">
        <div class="title">免费课程<a style="font-size:14px;margin-left:20px;">更多课程</a></div>
        <!-- <ul>
          <li @click="to(item.type,item.number)" v-for="item in arryData" :key="item">
                
                <img
                  :src="item.img"
                  class="image"
                  width="100%"
                />
            
                <p>{{item.title}}</p>
                <span class="go-learn">去学习</span>
              </li>
        </ul> -->
        <el-carousel
          :interval="4000"
          type="card"
          :height="heightChange"
          class="addL"
        >
          <el-carousel-item v-for="item in arryData" :key="item">
            <ul>
              <li @click="to(item.url)">
                <div>{{item.title}}</div>
                <p>{{item.detail}}</p>
                <img
                  :src="item.img"
                  class="image"
                  width="100%"
                />
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>

        <div class="title">我的任务</div>
        <div style="padding: 0 10px">
          <!-- <a-table
          :columns="columns"
          :data-source="dataC"
          :scroll="{ x:1000 ,y: 300 }"
          bordered
        >
          <a slot="action" slot-scope="text" @click="to('./detailVideo')"
            >作业</a
          >
        </a-table> -->
          <el-table :data="dataC" style="width: 100%" border>
            <el-table-column prop="key" label="序号"> </el-table-column>
            <el-table-column prop="title" label="所属班级"> </el-table-column>
            <el-table-column prop="school" label="布置时间"> </el-table-column>
            <el-table-column prop="number" label="结束时间"> </el-table-column>
            <el-table-column prop="grade" label="状态"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="toZy">{{
                  scope.row.course
                }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "../components/banner.vue";
import headTop from "../components/header.vue";

export default {
  name: "list",
  components: { banner, headTop },
  data() {
    return {
      dataC: [],
      arryData:[
        {
          title:'Scratch编程',
     
          img:'https://bth.educg.net/images/addImages/scratch-rumen.png',
          url:true
      
         
        },
        {
          title:'pYthon编程',
          detail:'',
          img:'https://bth.educg.net/images/WechatIMG111.png',
          url:false
         
        },
        {
          title:'C++编程',
          detail:'',
          img:'https://bth.educg.net/images/WechatIMG1091.png',
          url:false
       
        }
      ],
      heightChange: "300px",
      currentrStr: ['index'],
    };
  },
  mounted: function () {
    this.dataC=[
        {
          key: 1,
          title: `编程二班`,
          school: "2020-02-02",
          number: `2020-03-03`,
          grade: "已提交",
          course: "作业",
        },
        {
          key: 2,
          title: `编程二班`,
          school: "2020-02-02",
          number: `2020-03-03`,
          grade: "已提交",
          course: "作业",
        },
      ];
      console.log( this.dataC)
    if (window.localStorage.getItem("anjing-token-group") != "student") {
      this.$router.push({
        path: "./index",
      });
    }
    if (document.body.clientWidth <= 800) {
      this.heightChange = "180px";
    }
    if (document.body.clientWidth > 800 && document.body.clientWidth < 1000) {
      this.heightChange = "300px";
    }
    window.onresize = () => {
      if (document.body.clientWidth <= 800) {
        this.heightChange = "180px";
      }
      if (document.body.clientWidth > 800 && document.body.clientWidth < 1000) {
        this.heightChange = "300px";
      }
    };
  },
  methods: {
    toZy(){
      let routeUrl = this.$router.resolve({path:'/list-scratch-video',query:{
        title:'海洋馆',qid:'1649987968426',userid:'63771fdc3578d84b203947d8',jobid:'',id:'62306bd65a8acb4c20581244'

        }});
        window.open(routeUrl.href, '_blank')
    },
    to(url) {
      // onclick="targetUrl('/userInfo/scratch.html?type=Scratch编程入门&pathNum=0')"
      if(url){
        this.$router.push({ path: 'class',query:{type:'scratch'} });
      }
    
    },
    handleClick(row) {
      this.$router.push({
        path: "/detailVideo?title=课前准备&qid=1649985978179&userid=60191dab0d0f5248cc7c26d3&jobid=&id=62305e6ae9e1627608653807",
      });
    },
  },
};
</script>
<style  lang="scss" scoped>
ul,
li,
div,
p,
span {
  list-style: none;
  padding: 0;
  margin: 0;
}
.about-all {
  width: 1180px;
  margin: 0 auto;
}
.about {
  .title {
    padding: 20px 10px 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  .zuoye {
    display: flex;
    justify-content: center;
  }

  ul {
    // display: flex;
    //     justify-content: flex-start;
    // flex-wrap: wrap;
    // width: 444px;\    
    display: flex;
    flex-wrap: wrap;
    // padding:5px 10px;
    justify-content: space-around;
    
  }
  ul li {
    cursor: pointer;
    // float: left;
    /* width:30%; */
    margin-right: 3%;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    &:hover {
      box-shadow: 1px 1px 1px #ccc;
    }
    div {
      font-size: 24px;
      font-weight: bold;
    }
    p {
      text-align: left;
      // width: 235px;
      // margin-bottom: 10px;
      // text-align: center;
      padding: 10px 10px;
    font-weight: bold;
    }
  }
  ul li:last-child {
    margin-right: 0%;
  }
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 31.3%;
  //   display: inline-block;
  float: left;
  margin-right: 3.3333333%;
}
.a-card__header {
  background: #ececec;
}
.addL {
  width: 78%;
  margin: 0 auto;
}
.go-learn{
  // margin-top:10px;
  display: block;
  width:100%;
  height:30px;
  line-height: 30px;
  text-align: center;
  color:#fff;
  background: #1890ff;
}
@media (max-width: 1000px) {
  .about-all {
    width: 100%;
  }
  // .about ul {
  //   width: 304px;
  // }
}
@media (max-width: 800px) {
  // .about-all {
  //   width: 100%;
  // }
  .about ul li div {
    font-size: 16px;
  }
  .about ul li p {
    font-size: 12px;
  }
  .addL {
    width: 93%;
  }
  // .about ul {
  //   width: 169px;
  // }
}
</style>
