<template>
  <div class="detail">
    <a-container style="border: 1px solid #eee">
      <a-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <div style="height: 60px; line-height: 60px">学生</div>
        <a-menu :default-openeds="['1', '3']">
          <a-submenu index="1">
            <template slot="title"
              ><i class="a-icon-orange"></i>4~8岁</template
            >

            <a-menu-item index="1-1">第一章</a-menu-item>
            <a-menu-item index="1-2">第二章</a-menu-item>
          </a-submenu>
        </a-menu>
      </a-aside>

      <a-container>
        <a-header style="text-align: left; font-size: 16px; color: #fff">
          <div>当前视频:</div>
          <!-- <a-dropdown>
        <i class="a-icon-setting" style="margin-right: 15px"></i>
        <a-dropdown-menu slot="dropdown">
          <a-dropdown-item>查看</a-dropdown-item>
          <a-dropdown-item>新增</a-dropdown-item>
          <a-dropdown-item>删除</a-dropdown-item>
        </a-dropdown-menu>
      </a-dropdown>
      <span>王小虎</span> -->
        </a-header>

        <a-main class="right">
          <div>
            <iframe
              width="100%"
              height="605"
              style="margin-top: -20px"
              src="https://www.ixigua.com/iframe/7011347773907796511?autoplay=0"
              scrolling="no"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
            >
            </iframe>
          </div>
          <a-button
            style="
              flex: 1;
              height: 40px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            type="primary"
            @click="
              doLx(
                '1649985978179',
                '课前准备',
                '60191dab0d0f5248cc7c26d3',
                '',
                '62305e6ae9e1627608653807'
              )
            "
          >
            开始练习 <i class="a-icon-edit"></i>
          </a-button>
          <!-- <a-table :data="tableData">
        <a-table-column prop="date" label="日期" width="140">
        </a-table-column>
        <a-table-column prop="name" label="姓名" width="120">
        </a-table-column>
        <a-table-column prop="address" label="地址">
        </a-table-column>
      </a-table> -->
        </a-main>
      </a-container>
    </a-container>
  </div>
</template>
<script>
export default {
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(20).fill(item),
    };
  },
  methods: {
    doLx(qid, title, userid, jobid, id, e) {
        this.$router.push({path:'/detailVideo',query:{
            title:title,
            qid:qid,
            userid:userid,
            jobid:jobid,
            id:id

        }})

    },
  },
};
</script>
<style scoped>
.detail {
  height: calc(100vh - 61px);
}
.a-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.a-aside {
  color: #333;
}
.right {
  height: calc(100vh - 130px);
}
</style>
