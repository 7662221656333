<template>
<div>  <login :isShow='true'></login>
  <div class="login">

    <div>
      <p class="title">注册</p>
      <p class="ms">已有账号？ <router-link to="/login">去登录</router-link></p>
      <p class="ms">证件号将作为系统识别你身份的唯一校验，绑定后不可更改</p>
    </div>
    <a-steps :current="current" size="small">
      <a-step title="绑定手机" sub-title="" description="" />
      <a-step title="完善信息" sub-title="" description="" />
      <a-step title="设置密码" description="" />
    </a-steps>

    <div v-show="current == 0" class="inputMar">
      <a-form-model ref="ruleFormReg" :model="form" :rules="rules">
        <a-form-model-item
          ref="phone"
          label="手机号（国际/港澳台号码格式为：国家区号+手机号码）"
          prop="phone"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您手机号" v-model="form.phone">
            <a-icon slot="prefix" type="tablet"
          /></a-input>
        </a-form-model-item>
        <a-form-model-item
          ref="code"
          prop="code"
          label="验证码"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
          style="width: 77%; display: table-cell"
        >
          <a-input placeholder="请输入您的验证码" v-model="form.code">
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          style="width: 25%; display: table-cell; padding-top: 27px"
        >
          <a-button
            type="primary"
            :disabled="codeBtn"
            @click="codeFun"
            style="width: 96px; padding: 0; margin-left: 5px"
          >
            {{ codeText }}
          </a-button>

          <a-drawer
            title="温馨提示"
            placement="top"
            :closable="false"
            :visible="visible"
            :after-visible-change="afterVisibleChange"
            @close="onClose"
            width="400"
          >
            <p>1.请确认输入的手机号是否为您注册的手机号；</p>
            <p>2.请检查短信是否被手机安全软件拦截；</p>
            <p>3.由于网络原因，短信可能会延迟；</p>
            <p>4.若多次未收到短信，请咨询客服。</p>
          </a-drawer>
        </a-form-model-item>
        <p class="overflow:hidden;" @click="showDrawer">
          <span class="codeQ">没收到验证码？</span>
        </p>
        <a-form-model-item>
          <a-button type="primary" @click="onSubmitReg" style="width: 100%">
            下一步
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div v-show="current == 1" class="inputMar">
      <a-form-model ref="ruleFormZJ" :model="form" :rules="rules">
        <a-form-model-item
          ref="name"
          label="姓名"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您的姓名" v-model="form.name">
            <a-icon slot="prefix" type="user"
          /></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="性别"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
          ref="sex"
        >
          <a-select v-model="form.sex" placeholder="请选择性别">
            <a-select-option value="男"> 男 </a-select-option>
            <a-select-option value="女"> 女 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="证件类型"
          labelAlign="left"
          :labelCol="{ span: 24, offset: 0 }"
          ref="type"
        >
          <a-select v-model="form.type" placeholder="请选择证件类型">
            <a-select-option value="居民身份证"> 居民身份证 </a-select-option>
            <a-select-option value="港澳台身份证">
              港澳台身份证
            </a-select-option>
            <a-select-option value="护照"> 护照 </a-select-option>
            <a-select-option value="回乡证"> 回乡证 </a-select-option>
            <a-select-option value="其他"> 其他 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          ref="card"
          label="证件号"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您的证件号" v-model="form.card">
            <a-icon slot="prefix" type="credit-card"
          /></a-input>
        </a-form-model-item>
        <a-form-model-item
          v-if="typeBri"
          label="生日"
          labelAlign="left"
          :labelCol="{ span: 24, offset: 0 }"
          ref="bri"
          prop="bri"
        >
          <a-date-picker
            v-model="form.bri"
            :locale="locale"
            placeholder="请选择生日"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="onSubmitZJ" style="width: 100%">
            下一步
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div v-show="current == 2" class="inputMar">
      <a-form-model ref="ruleFormPas" :model="form" :rules="rules">
        <a-form-model-item
          label="密码（请输入6~20位密码）"
          prop="pass"
          ref="pass"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input-password placeholder="请输入您的密码" v-model="form.pass">
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item
          label="确认密码"
          prop="passR"
          ref="passR"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input-password
            placeholder="请再次输入您的密码"
            v-model="form.passR"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="onSubmitPas" style="width: 100%">
            注册
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

  </div>
  </div>
</template>
<script>
import login from '../components/login.vue'
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
export default {
  components: {
    login
  },
  data() {
    return {
      typeBri: false,
      locale,
      visible: false,
      current: 0,
      btnSize: { span: 23, offset: 2 },
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      other: "",
      codeBtn: true,
      phoneCode: true,
      codeText: "发送验证码",
      countdown: 5,
      form: {
        // name: "",
        // pass: "",
        code: "",
        phone: "",
        name: "",
        sex: undefined,
        type: undefined,
        card: "",
        bri: undefined,
        pass: "",
        passR: "",

        // date1: undefined,
        // delivery: false,
        // type: [],
        // resource: "",
        // desc: "",
      },
      rules: {
        // name: [
        //   {
        //     required: true,
        //     message: "请输入您的密码",
        //     trigger: "blur",
        //   },
        //   {
        //     min: 3,
        //     max: 20,
        //     message: "请输入3-20个字符",
        //     trigger: "blur",
        //   },
        // ],
         passR: [
          {
            required: true,
            message: "请输入您确认密码",
            trigger: "blur",
          },
         
          {
            validator: this.checkPassR,
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "请输入6-20个字符",
            trigger: "blur",
          },
          {
            validator: this.checkPass,
            trigger: "blur",
          },
        ],
        bri: [
          {
            required: true,
            message: "请输入您的生日",
            trigger: "change",
          },
        ],
        card: [
          {
            required: true,
            message: "请输入您的证件号",
            trigger: "blur",
          },
          {
            validator: this.checkCard,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入您的验证码",
            trigger: "blur",
          },
          {
            min: 1,
            max: 6,
            message: "请输入1-6个字符",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择证件类型", trigger: "change" },
          {
            validator: this.checkType,
            trigger: "change",
          },
        ],
        sex: [
          { required: true, message: "请选择性别", trigger: "change" },
          {
            validator: this.checkSex,
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入您的手机号",
            trigger: "blur",
          },
          {
            validator: this.checkGrade,
            trigger: "blur",
          },
        ],

        name: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "blur",
          },
          {
            validator: this.checkName,
            trigger: "blur",
          },
        ],
        // region: [
        //   {
        //     required: true,
        //     message: "Please select Activity zone",
        //     trigger: "change",
        //   },
        // ],
        // date1: [
        //   { required: true, message: "Please pick a date", trigger: "change" },
        // ],
        // type: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "Please select at least one activity type",
        //     trigger: "change",
        //   },
        // ],
        // resource: [
        //   {
        //     required: true,
        //     message: "Please select activity resource",
        //     trigger: "change",
        //   },
        // ],
        // desc: [
        //   {
        //     required: true,
        //     message: "Please input activity form",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    codeFun() {
      this.settime();
    },
    settime() {
      //发送验证码倒计时
      if (this.countdown == 0) {
        this.countdown = 5;
        this.codeText = "发送验证码";

        this.codeBtn = this.phoneCode;
        return;
      } else {
        this.codeText = this.countdown;
        this.codeBtn = true;

        this.countdown--;
      }
      setTimeout(() => {
        this.settime();
      }, 1000);
    },
    // 证件号规则：证件类型是身份证时要校验证件号和性别匹配不
    checkCard(rule, value, callback) {
      let structRight = RegExp(
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的证件号"));
      }
      // 如果正确 就要去判断证件类型-证件号和性别
      else {
        callback();
      }
    },
    // 修改密码
    checkPass(rule, value, callback) {
      if (this.form.passR != "" && this.form.pass != this.form.passR) {
        callback(new Error("两次密码输入不一致"));
      }
      else {
          callback();
      }
    },
    checkPassR(rule, value, callback) {
      if (this.form.pass != "" && this.form.pass != this.form.passR) {
        callback(new Error("两次密码输入不一致"));
      }
      else {
          callback();
      }
    },
    checkName(rule, value, callback) {
      // 姓名校验规则：失去焦点 判断一下证件类型 身份证-中文，否则中文+英文 校验姓名和身份证的关联

      // 居民身份证== 中文
      let structRight = RegExp(/^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/).test(
        value
      );
      // 非居民身份证==中文+英文
      let structLeft = RegExp(/^[A-Za-z]*(\s[A-Za-z]*)*$/).test(value);
      // if ( ) {
      // }
      // 证件类型为空
      if (!(structRight || structLeft) && this.form.type == undefined) {
        callback(new Error("姓名不允许是数字、特殊符号、中英文结合"));
      }
      // 证件类型不为空
      else if (
        this.form.type != undefined &&
        this.form.type == "居民身份证" &&
        !structRight
      ) {
        callback(new Error("证件类型为居民身份证时,考生姓名必须输入中文"));
      } else if (
        this.form.type != undefined &&
        this.form.type != "居民身份证" &&
        !(structRight || structLeft)
      ) {
        callback(new Error("姓名不允许是数字、特殊符号、中英文结合"));
      } else {
        callback();
      }
    },
    // 性别校验规则：性别-如果证件类型是身份证的情况下要和身份号证校验
    checkSex(rule, value, callback) {
      if (
        this.form.type != undefined &&
        this.form.type == "居民身份证" &&
        this.form.card != ""
      ) {
        // .substr(16, 1)) % 2 == 1 男
        if (
          (this.form.card.substr(16, 1) % 2 == 1 && this.form.sex == "女") ||
          (this.form.card.substr(16, 1) % 2 == 0 && this.form.sex == "男")
        ) {
          callback(new Error("身份证号与性别不一致"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // 证件类型校验； 如果为空-不校验性别和身份证 不校验姓名 隐藏生日  否则相反 typeBri
    checkType(rule, value, callback) {
      this.checkSex(rule, this.form.sex, callback);
      this.checkName(rule, this.form.name, callback);
      if (this.form.type != undefined && this.form.type == "居民身份证") {
        this.typeBri = false;
      } else {
        this.typeBri = true;
      }
    },
    checkGrade(rule, value, callback) {
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的手机号"));
        this.codeBtn = true;
        this.phoneCode = true;
      } else {
        callback();
        this.codeBtn = false;
        this.phoneCode = false;
      }
    },
    // onSubmit() {
    //   this.$refs.ruleForm.validate((valid) => {
    //     if (valid) {
    //       alert("submit!");
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    onSubmitPas() {
      this.$refs.ruleFormPas.validate((valid) => {
        if (valid) {
          alert("submit!");
          // this.current = 1;
          this.$router.push("./index");
          // window.localStorage.setItem('anjing-to','index')
        } else {
          console.log("error submit!!");
          // this.current = 0;
          return false;
        }
      });
    },
    onSubmitReg() {
      this.$refs.ruleFormReg.validate((valid) => {
        if (valid) {
          alert("submit!");
          this.current = 1;
        } else {
          console.log("error submit!!");
          this.current = 0;
          return false;
        }
      });
    },
    //ruleFormZJ
    onSubmitZJ() {
      this.$refs.ruleFormZJ.validate((valid) => {
        if (valid) {
          alert("submit!");
          this.current = 2;
        } else {
          console.log("error submit!!");
          this.current = 1;
          return false;
        }
      });
    },
    // resetForm() {
    //   this.$refs.ruleForm.resetFields();
    // },
    onFieldBlur() {
      console.log("ZHELI ");
    },
    callback() {},
  },
};
</script>
<style  lang="scss" scoped>
.login {
  max-width: 450px;
  padding: 10px;
  margin: 0 auto;
  margin-top: 100px;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .ms {
    font-size: 14px;
  }
  .codeQ {
    color: #69a4fa;
    cursor: pointer;
    float: right;
  }
  .for {
    float: right;
    // margin: -15px 15px 15px;
  }
  .inputMar {
    margin-top: 30px;
  }
}
.ant-form-item-label {
  display: block !important;
  text-align: left;
}
.ant-steps-item {
  flex: 1;
}
// .ant-form-item-required{
//   font-weight: bold !important;
// }
// .ant-form-item-label{
//   display: block;
//   text-align: left;
// }
</style>
