<template>
   <div id="con">

        <!-- <a-aside width="200px" style="background-color: rgb(238, 241, 246)"> -->
        <!-- <div class="title-con">scratch </div> -->
        <a-container style="height: calc(100vh - 100px); border: 1px solid #eee">

            <!-- </a-aside> -->
            <a-container style="border: 1px solid #eee">
                <!-- <a-header class="title-con"> </a-header> -->
                    <!-- <div class="title-color">{{changeTitle}}</div> -->
                    <!-- <div class="title-color">练习内容：{{changeTitle}}</div> -->
                    <!-- <a-button type="primary" plain style="float:right;margin-top: -43px;" onclick="location.reload();">
                        重新加载</a-button> -->

               
                <a-main v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="a-icon-loading" style="padding:0;">
                    <iframe class="iframe-style" :src="iframeSrc" style='width:100%;height: calc(100vh - 120px);'></iframe>

                </a-main>
            </a-container>

        </a-container>

    </div>
</template>
<script>
export default {
  data() {
   
    return {
      changeTitle: "",
            qid: '',
            userid: '',
            jobid: '',
            iframeSrc: '',
            loading: true,
            timeE: null,
            timeEnd: 0
    };
  },

        mounted() {
            this.changeTitle = this.getQueryString('title');
            this.qid = this.getQueryString('qid');
            this.userid = this.getQueryString('userid');
            this.jobid = this.getQueryString('jobid');
            this.id = this.getQueryString('id');

            this.iframeSrc = `https://scratch.educg.net?TcTylianxi=true&qid=${this.qid}&userid=${this.userid}&jobid=${this.jobid}`
            setTimeout(() => { this.loading = false; }, 500)
            this.timeE = setInterval(() => {
                this.timeEnd++;

            }, 1000)






        },
  methods: {
     getQueryString(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
                var r = window.location.search.substr(1).match(reg);
                if (r != null) return decodeURI(r[2]); return null;
            },
 
  },
};
</script>

